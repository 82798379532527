import React from 'react';
import { UsersFindMany, ThemesFindMany, LifeSkillFindMany, SkillSetFindMany} from '../../../hoc';
import {TagsSelector, TextArea} from '../../../components';
import {ArrowIndicatorIcon, ImageIcon} from '../../../icons';
import Input from '../../../components/ui/Input';

export const CoursesCreate = ({
	preview,isLoading,isError,
	formState,
	selectedThemes,
	selectedSkillSets,
	handleFileChange,
	selectedLifeSkills,
	setSelectedSkillSets,
	setSelectedLifeSkills,
	 setSelectedThemes,
	allFieldsFilled,
	handleChange,
	handleRadioChange,
	handleRadioChangeDonation,
	handleSubmit,
}) => {

	return (
		<div className={'w-100 h-100'}>
			<div className={'flex gap-1 justify-start align-center'} onClick={() => history.goBack()}>
				<ArrowIndicatorIcon className={'transform rotate-180 cursor-pointer'} />
				<h2 className={'font-sans text-text-light dark:text-text-dark m-2 text-base font-medium cursor-pointer'}>Back</h2>
			</div>
			<h1 className={'font-sans text-text-light dark:text-text-dark m-2 text-base font-medium'}>Create Course</h1>
			<div className={'flex gap-2 justify-center align-center rounded-lg'}
				 style={{backgroundColor: 'rgba(11, 23, 57, 1)', border: '1px solid rgba(52, 59, 79, 1)'}}>
				<form className={'p-5 w-100 flex direction-row justify-start align-center gap-7'}
					  onSubmit={handleSubmit}>
					<div className={'w-100'}>
						<div className={'mb-4 flex gap-2 justify-center align-center'}>
							<label htmlFor='file-upload'
								   className={'w-45 h-45 cursor-pointer flex align-center justify-center direction-column'}>
								<div className={'flex justify-center align-center w-44 h-44 rounded-full'}
									 style={{backgroundColor: 'rgba(52, 59, 79, 1)'}}>
									{preview ? (
										<img src={preview} alt='Preview'
											 className={'rounded-full w-44 h-44 object-cover'}/>
									) : (
										<ImageIcon width={'3em'} height={'3em'} className={'cursor-pointer'}/>
									)}
								</div>
								<span
									className={'font-sans mt-3 ml-3 text-text-light dark:text-text-dark active-nav-item-font-color cursor-pointer'}>
									Upload Image
								</span>
								<input
									id='file-upload'
									type='file'
									accept='image/*'
									onChange={handleFileChange}
									className={'hidden'}
								/>
							</label>

							<div className={'w-full ml-4'}>
		
								<Input
									type='text'
									label={'Title'}
									name='title'
									value={formState.title}
									onChange={handleChange}
								/>

								<TextArea
									label={'Description'}
									name='description'
									value={formState.description}
									onChange={handleChange}
								/>

							</div>
						</div>
						<div className={'mb-4 w-100 flex direction-column justify-start align-center self-start'}>
							<label
								className={'font-sans ml-3 mb-4 text-text-light dark:text-text-dark self-start'}>Teacher</label>
							<UsersFindMany>
								{users => (
									<select
										name='teacher'
										value={formState.teacher}
										onChange={handleChange}
										className={'font-mono w-100 p-5 text-text-light dark:text-text-dark bg-background-light dark:bg-background-dark rounded-lg'}
									>
										<option value=''>Select a Teacher</option>
										{users.map(user => (
											<option key={user._id} value={user._id}>
												{user.firstName} {user.lastName}
											</option>
										))}
									</select>
								)}
							</UsersFindMany>
						</div>
						<div className={'w-100 flex mb-4 direction-column justify-start align-center self-start'}>
							<label className={'font-sans ml-3 text-text-light dark:text-text-dark self-start'}>Set
								Status in your Curriculum:</label>
						</div>
						<div className={'flex justify-center align-center gap-5 mb-4'}>
							<label className={'font-sans ml-3 text-text-light dark:text-text-dark'}>Active</label>
							<input
								type='radio'
								name='active'
								value='true'
								checked={formState.active === true}
								onChange={handleRadioChange}
								className={'font-mono p-5'}
							/>
							<label className={'font-sans mr-3 text-text-light dark:text-text-dark'}>Inactive</label>
							<input
								type='radio'
								name='active'
								value='false'
								checked={formState.active === false}
								onChange={handleRadioChange}
								className={'font-mono p-5'}
							/>
						</div>
						<div className={'w-full grid grid-cols-2 gap-4'}>
							<Input
								label={'Slug'}
								type='text'
								name='slug'
								value={formState.slug}
								onChange={handleChange}
							/>
							<Input
								type='text'
								label={'Course Duration'}
								name='courseDuration'
								value={formState.courseDuration}
								onChange={handleChange}
							/>
							<Input
								type='text'
								label={'Lesson Length'}
								name='lessonLength'
								value={formState.lessonLength}
								onChange={handleChange}
							/>
							<Input
								type='number'
								label={'Total Lessons'}
								name='totalLessons'
								value={formState.totalLessons}
								onChange={handleChange}
							/>
						</div>
							

						<TextArea
							label={'Leader Bio'}
							name='leaderBio'
							value={formState.leaderBio}
							onChange={handleChange}
						/>

						<TextArea
							label={'Goal'}
							name='learningGoal'
							value={formState.learningGoal}
							onChange={handleChange}
						/>
					
						<div className={'w-100 flex mb-4 direction-column justify-start align-center self-start'}>
							<label className={'font-sans ml-3 text-text-light dark:text-text-dark self-start'}>Set
								Status for Donations in your Curriculum:</label>
						</div>
						<div className={'flex justify-center align-center gap-5 mb-4'}>
							<label className={'font-sans ml-3 text-text-light dark:text-text-dark'}>Donation
								Enabled</label>
							<input
								type='radio'
								name='donation'
								value='true'
								checked={formState.donation === true}
								onChange={handleRadioChangeDonation}
								className={'font-mono p-5'}
							/>
							<label className={'font-sans mr-3 text-text-light dark:text-text-dark'}>Donation
								Disabled</label>
							<input
								type='radio'
								name='donation'
								value='false'
								checked={formState.donation === false}
								onChange={handleRadioChangeDonation}
								className={'font-mono p-5'}
							/>
						</div>
						<TagsSelector
							selectedTags={selectedLifeSkills}
							setSelectedTags={setSelectedLifeSkills}
							HOC={LifeSkillFindMany}
							label='Life Skills'
						/>
						<TagsSelector
							selectedTags={selectedSkillSets}
							setSelectedTags={setSelectedSkillSets}
							HOC={SkillSetFindMany}
							label='Skill Sets'
						/>
						<TagsSelector
							selectedTags={selectedThemes}
							setSelectedTags={setSelectedThemes}
							HOC={ThemesFindMany}
							label='Themes'
						/>
						<div className={'flex center justify-center align-center mt-6'}>
							<button
								type='submit'
								disabled={!allFieldsFilled || isLoading}
								className={`flex mb-3 self-center align-center justify-center button t-white button-large w-100 br-quarter hover:grey900 f-uppercase f-900 bg-gradient-to-r from-brightLilac-default to-indigo-default ${
									!allFieldsFilled ? 'opacity-50 cursor-not-allowed' : 'hover:bg-button-hover-light dark:hover:bg-button-hover-dark'
								}`}
							>
								{isLoading ? 'Creating Course...' : 'Create Course'}
							</button>
						</div>
						{isError &&
							<p className={'text-red-500'}>{isError.message ? isError.message : 'An error occurred'}</p>}
					</div>
				</form>
			</div>
		</div>
	);
};