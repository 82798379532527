import {useEffect, useState} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {useMutation, useQuery} from '@apollo/client';
import {query} from '../../../../actions/queries';
import {
	uploadCoursesCover,
	UploadCoursesTrailerClosedCaptions,
	UploadCurriculumTrailer
} from '../../../Courses/actions/Coures.rest';
import {removeTypename as removeTypenameFunction} from '../../../../tools';
import {InfoRender} from './Info.render';
import {coursesMutations} from '../../../Courses/actions/Courses.mutations';
import {coursesQueries} from '../../../Courses/actions/Courses.queries';

export const InfoLoad = () => {
	const { id } = useParams();
	const history = useHistory();
	const { loading, error, data , refetch} = useQuery(coursesQueries('CurriculumsFindOne'), {
		variables: { id },
		nextFetchPolicy: true
	});
	const { data: lifeSkillsData } = useQuery(query('lifeSkillsFindMany'), {
		variables:{ limit: 1000 },
		fetchPolicy: 'network-only'
	});

	const { data: skillSetsData } = useQuery(query('skillSetFindMany'), {
		variables:{ limit: 1000 },
		fetchPolicy: 'network-only'
	});

	const { data: themesData } = useQuery(query('ThemeFindMany'), {
		variables:{ limit: 1000 },
		fetchPolicy: 'network-only'
	});

	const [selectedLifeSkills, setSelectedLifeSkills] = useState([]);
	const [selectedSkillSets, setSelectedSkillSets] = useState([]);
	const [selectedThemes, setSelectedThemes] = useState([]);
	const [selectedFile, setSelectedFile] = useState(null);
	const [preview, setPreview] = useState(null);
	const [trailer, setTrailer] = useState(null);
	const [trailerPreview, setTrailerPreview] = useState(null);
	const [isChanged, setIsChanged] = useState(false);

	const [course, setCourse] = useState({
		title: '',
		description: '',
		teacher: '',
		createdAt: '',
		updatedAt: '',
		active: false,
		hidden: false,
		slug: '',
		animalCategory: '',
		leaderBio: '',
		learningGoal: '',
		lifeSkill: [],
		skillSet: [],
		theme: [],
		cover: { url: '', type: '' },
		loop: { url: '', type: '' },
		logo: { url: '', type: '' },
		trailer: { url: '', type: '', captions: [] },
		documents: [],
		language: {
			spanish: { title: '', description: '', identifier: '', label: '' },
			english: { title: '', description: '', identifier: '', label: '' },
			french: { title: '', description: '', identifier: '', label: '' },
		},
		resources: [],
		donation: false,
	});

	const [formState, setFormState] = useState({ ...course });
	const [subtitleState, setSubtitleState] = useState({
		file: null,
		error: '',
		loading: false,
	});

	useEffect(() => {
		if (data) {
			const curriculum = data.CurriculumsFindOne;

			setCourse(curriculum);
			setFormState({
				...curriculum,
				lifeSkill: curriculum.lifeSkill || [],
				skillSet: curriculum.skillSet || [],
				theme: curriculum.theme || []
			});
			if (lifeSkillsData) {
				const mappedLifeSkills = curriculum.lifeSkill.map(skillId =>
					lifeSkillsData.LifeSkillFindMany.find(skill => skill._id === skillId)
				);
				setSelectedLifeSkills(mappedLifeSkills);
			}
			if (skillSetsData) {
				const mappedSkillSets = curriculum.skillSet.map(skillSetId =>
					skillSetsData.SkillSetFindMany.find(skillSet => skillSet._id === skillSetId)
				);
				setSelectedSkillSets(mappedSkillSets);
			}
			if (themesData) {
				const mappedThemes = curriculum.theme.map(themeId =>
					themesData.ThemeFindMany.find(theme => theme._id === themeId)
				);
				setSelectedThemes(mappedThemes);
			}
		}
	}, [data, lifeSkillsData, skillSetsData, themesData, subtitleState.file]);

	const [updateCourse, { loading: isLoading, error: isError }] = useMutation(coursesMutations('curriculumsUpdateOne'), {
		refetchQueries: [{ query: query('curriculumsFindMany') }],
		awaitRefetchQueries: true
	});

	const removeTypename = (obj) => removeTypenameFunction(obj);

	const handleChange = (e) => {
		const { name, value, type, checked } = e.target;
		const inputValue = type === 'checkbox' ? checked : value;
		setFormState((prev) => ({ ...prev, [name]: inputValue }));
		setIsChanged(true);
	};

	const handleRadioChange = (e) => {
		const { value } = e.target;
		setFormState((prev) => ({ ...prev, active: value === 'true' }));
		setIsChanged(true);
	};

	const handleRadioChangeDonation = (e) => {
		const { value } = e.target;
		setFormState((prev) => ({ ...prev, donation: value === 'true' }));
		setIsChanged(true);
	};

	const handleFileChange = (e, fileType) => {
		const file = e.target.files[0];
		if (!file) return;

		if (fileType === 'cover') {
			setSelectedFile(file);
			setPreview(URL.createObjectURL(file));
		} else if (fileType === 'trailer') {
			setTrailer(file);
			setTrailerPreview(URL.createObjectURL(file));
		} else if (fileType === 'captions') {
			setSubtitleState(prev => ({
				...prev,
				file,
				error: '',
				loading: true
			}));
		} else {
			console.warn('Unsupported file type');
		}
		setIsChanged(true);
	};

	const handleTrailerChange = (e) => {
		const file = e.target.files[0];

		if (file) {
			setTrailer(file);
			setTrailerPreview(URL.createObjectURL(file));
		} else {
			setTrailer(null);
			setTrailerPreview(null);
		}
		setIsChanged(true);
	};

	const handleLifeSkillsChange = (updatedSkills) => {
		setSelectedLifeSkills(updatedSkills);
		setFormState((prev) => ({
			...prev,
			lifeSkill: updatedSkills.map(skill => skill._id),
		}));
		setIsChanged(true);
	};

	const handleSkillSetsChange = (updatedSkills) => {
		setSelectedSkillSets(updatedSkills);
		setFormState((prev) => ({
			...prev,
			skillSet: updatedSkills.map(skill => skill._id),
		}));
		setIsChanged(true);
	};

	const handleThemesChange = (updatedThemes) => {
		setSelectedThemes(updatedThemes);
		setFormState((prev) => ({
			...prev,
			theme: updatedThemes.map(theme => theme._id),
		}));
		setIsChanged(true);
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		try {
			const cleanedFormState = removeTypename(formState);
			const { _id, createdAt, updatedAt, ...record } = cleanedFormState;

			const lifeSkillIds = selectedLifeSkills.map(skill => skill?._id).filter(Boolean);
			const skillSetIds = selectedSkillSets.map(skill => skill?._id).filter(Boolean);
			const themeIds = selectedThemes.map(theme => theme?._id).filter(Boolean);

			await updateCourse({
				variables: {
					_id: id ,
					record: {
						...record,
						totalLessons:formState.totalLessons && parseInt(formState.totalLessons),
						lifeSkill: lifeSkillIds,
						skillSet: skillSetIds,
						theme: themeIds,
					}
				},
				refetchQueries: [{ query: query('curriculumsFindMany') }],
				awaitRefetchQueries: true,
			});

			if (selectedFile) {
				await uploadCoursesCover(selectedFile, id);
			}

			if (trailer) {
				await UploadCurriculumTrailer(trailer, id);
			}

			if (subtitleState.file) {
				await UploadCoursesTrailerClosedCaptions(subtitleState.file, id, true, formState.language);
			}

			setIsChanged(false);
			history.push('/courses');
		} catch (error) {
			console.error('Error updating course:', error);
		} finally {
			console.log('Course Edited Created');
			history.push('/courses');
		}
	};

	const getImageSrc = () => {
		if (preview) return preview;
		if (formState.cover.url) return formState.cover.url;
		return null;
	};

	const getVideoTrailerSrc = () => {
		if (trailerPreview) {
			return trailerPreview;
		} else if (formState?.trailer?.url) {
			return formState?.trailer?.url;
		} else {
			return null;
		}
	};

	if (loading) return <span className={'font-mona f-900 text-white p-3'}>Loading...</span>;
	if (error) return <span className={'font-mona f-900 text-white p-3'}>Error: {error.message}</span>;

	return (
		<InfoRender
			history={history}
			formState={formState}
			subtitleState={subtitleState}
			selectedLifeSkills={selectedLifeSkills}
			selectedSkillSets={selectedSkillSets}
			selectedThemes={selectedThemes}
			isChanged={isChanged}
			trailerPreview={trailerPreview}
			isLoading={isLoading}
			isError={isError}
			refetch={refetch}
			getImageSrc={getImageSrc}
			getVideoTrailerSrc={getVideoTrailerSrc}
			handleChange={handleChange}
			handleFileChange={handleFileChange}
			handleTrailerChange={handleTrailerChange}
			handleLifeSkillsChange={handleLifeSkillsChange}
			handleSkillSetsChange={handleSkillSetsChange}
			handleThemesChange={handleThemesChange}
			handleRadioChange={handleRadioChange}
			handleRadioChangeDonation={handleRadioChangeDonation}
			handleSubmit={handleSubmit}
		/>
	);
};