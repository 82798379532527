import gql from 'graphql-tag';

export const mutations = {
	//Create One:
	curriculumsCreateOne: gql`
        mutation CurriculumsCreateOne($record: CreateOnecurriculumsInput!) {
            CurriculumsCreateOne(record: $record) {
                recordId
                record {
                    _id
                    totalLessons
                    lessonLength
                    courseDuration
                    title
                    description
                    hidden
                    slug
                    teacher
                    animalCategory
                    documents {
                        title
                        url
                        type
                    }
                    language {
                        spanish {
                            title
                            description
                            identifier
                            label
                        }
                        english {
                            title
                            description
                            identifier
                            label
                        }
                        french {
                            title
                            description
                            identifier
                            label
                        }
                        _id
                    }
                    leaderBio
                    leaderName
                    active
                    cover {
                        url
                        type
                    }
                    loop {
                        url
                        type
                    }
                    logo {
                        url
                        type
                    }
                    trailer {
                        url
                        type
                    }
                    learningGoal
                    lifeSkill
                    skillSet
                    theme
                    donation
                    resources {
                        _id
                        url
                        type
                        title
                    }
                    createdAt
                    updatedAt
                }
                error {
                    message
                }
            }
        }
	`,
	//Update One:
	curriculumsUpdateOne: gql`
        mutation CurriculumsUpdateOne($_id: String!, $record: UpdateByIdcurriculumsInput!) {
            CurriculumsUpdateOne(_id: $_id, record: $record) {
                recordId
                record {
                    totalLessons
                    lessonLength
                    courseDuration
                    title
                    description
                    hidden
                    slug
                    teacher
                    animalCategory
                    documents {
                        title
                        url
                        type
                    }
                    language {
                        spanish {
                            title
                            description
                            identifier
                            label
                        }
                        english {
                            title
                            description
                            identifier
                            label
                        }
                        french {
                            title
                            description
                            identifier
                            label
                        }
                        _id
                    }
                    leaderBio
                    active
                    cover {
                        url
                        type
                    }
                    loop {
                        url
                        type
                    }
                    logo {
                        url
                        type
                    }
                    trailer {
                        url
                        type
                    }
                    learningGoal
                    lifeSkill
                    skillSet
                    theme
                    donation
                    resources {
                        _id
                        url
                        type
                        title
                    }
                    createdAt
                    updatedAt
                }
                recordId
            }
        }
    `,
};

export const coursesMutations = ( value ) => {
	return mutations[value];
};